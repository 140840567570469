import React, { useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Counters, Movies } from "./pages";
import Header from "./components/Layout/Header";
import Home from "./components/home/home";
import { UserProfile } from "./API/Google";

interface Tab {
  name: string;
  Component: () => JSX.Element;
}

function App() {
  const [user, setUser] = useState<UserProfile>();

  const tabs: Tab[] = [{ name: "Counters", Component: Counters }];
  if (user) tabs.push({ name: "Cinema", Component: Movies });

  return (
    <div className="App">
      <Header
        pages={tabs.map((tab) => tab.name)}
        settings={["About", "Contact"]}
        user={user}
        setUser={setUser}
      />
      <div className="content">
        <Routes>
          {tabs.map(({ name, Component }) => (
            <Route key={name} path={"" + name} Component={Component} />
          ))}

          <Route path="/" Component={Home} />
          <Route path="*" Component={Home} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
