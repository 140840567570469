import React, { useState } from "react";
import { Button, Chip, Grid } from "@mui/material";
import "./counters.css";
import { Counter } from "../../components";

const Counters = () => {
  const [counters, setCounters] = useState([{ value: 0 }, { value: 0 }, { value: 0 }]);

  const handleChange = (index: number, newValue: number) => {
    const newCounters = [...counters];
    newCounters[index].value = newValue;
    setCounters(newCounters);
  };

  const handleDelete = (index: number) => {
    const newCounters = [...counters];
    newCounters.splice(index, 1);
    setCounters(newCounters);
  };

  return (
    <div className="counters-container">
      <Grid container spacing={2} className="counter-container">
        <Grid item xs={1}>
          <Chip
            variant="filled"
            color="secondary"
            label={`Active ${counters.filter(({ value }) => value).length}`}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => setCounters([...counters, { value: 0 }])}
            variant="contained"
            color="info"
          >
            Add Counter
          </Button>
        </Grid>
        <Grid item xs={1.5}>
          <Button
            onClick={() =>
              setCounters(
                counters.map((counter) => {
                  return { ...counter, value: 0 };
                })
              )
            }
            variant="outlined"
            color="warning"
            disabled={counters.filter(({ value }) => value).length === 0}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      {counters.map((counter, index: number) => (
        <Counter
          key={index}
          {...counter}
          onChange={(newValue: number) => handleChange(index, newValue)}
          onDelete={() => handleDelete(index)}
        />
      ))}
    </div>
  );
};

export default Counters;
