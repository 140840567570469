import { useState } from "react";
import { deleteMovie, getMovies } from "./services/fakeMovieService";
import { getGenres } from "./services/fakeGenreService";
import "./movies.css";
import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Genre, Movie } from "./models";
const _ = require("lodash");

const Movies = () => {
  const maxResultsPerPage: number = 4;

  const Genres: Genre[] = getGenres();

  const [genreSelected, setSelectedGenre]: [Genre, React.Dispatch<React.SetStateAction<Genre>>] =
    useState({ _id: "", name: "" });

  const filteredMovies = (genre: Genre = genreSelected) =>
    genre._id.length
      ? getMovies().filter(({ genre: filteredGenre }) => genre._id === filteredGenre._id)
      : getMovies();

  const [Movies, setMovies]: [Movie[], React.Dispatch<React.SetStateAction<Movie[]>>] = useState(
    filteredMovies()
  );

  const [currentPage, setCurrentPage] = useState(1);

  const handleFavoriteChanged = (_id: string) => {
    const alteredMovies = [...Movies];
    const selectedMovie = alteredMovies.find((movie) => movie._id === _id);
    if (selectedMovie) selectedMovie.favorite = !selectedMovie.favorite;
    setMovies(alteredMovies);
  };

  const moviesToPresent = () => {
    const firstMovieIndex = (currentPage - 1) * maxResultsPerPage;
    const lastMovieIndex = firstMovieIndex + maxResultsPerPage;

    // in case we deleted last record in page
    // 8 movies left after deleting the 9th and we are currently presenting 9 - 12
    if (Movies.length === firstMovieIndex) setCurrentPage(currentPage - 1);

    return Movies.slice(firstMovieIndex, lastMovieIndex);
  };

  const moviesMessage = () => {
    const firstMovieIndex = (currentPage - 1) * maxResultsPerPage;
    const firstMovieIndexToShow = firstMovieIndex + 1;

    const lastMovieIndex = firstMovieIndex + maxResultsPerPage;
    const lastMovieIndexToShow = lastMovieIndex < Movies.length ? lastMovieIndex : Movies.length;

    const movieIndexRanges =
      firstMovieIndexToShow !== lastMovieIndexToShow
        ? `${firstMovieIndexToShow}-${lastMovieIndexToShow}`
        : firstMovieIndexToShow;

    return Movies.length
      ? `${movieIndexRanges} out of ${Movies.length}`
      : `No movies left in the database`;
  };

  const handleDelete = (_id: string) => {
    deleteMovie(_id);
    setMovies(filteredMovies());
  };

  const handleGenreChanged = (selectedGenre: Genre) => {
    setSelectedGenre(selectedGenre);

    const moviesToShow = filteredMovies(selectedGenre);
    setMovies(moviesToShow);

    if (moviesToShow.length / maxResultsPerPage <= 1) setCurrentPage(1);
  };

  return (
    <Container className="movies-container">
      <h2>{moviesMessage()}</h2>
      {Movies.length !== 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={2} className="genres-container">
              <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                <Button
                  variant={!genreSelected._id.length ? "contained" : "outlined"}
                  onClick={() => handleGenreChanged({ _id: "", name: "" })}
                >
                  All Genre
                </Button>
                {Genres.map(({ _id, name }) => (
                  <Button
                    variant={genreSelected._id === _id ? "contained" : "outlined"}
                    onClick={() => handleGenreChanged({ _id, name })}
                    key={_id}
                  >
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>

            <Grid item xs={10}>
              <TableContainer className="movies-table-container" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Title</TableCell>
                      <TableCell align="center">Genre</TableCell>
                      <TableCell align="center">Stock</TableCell>
                      <TableCell align="center">Rate</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {moviesToPresent().map(
                      ({ _id, title, genre, numberInStock, dailyRentalRate, favorite }) => (
                        <TableRow key={_id}>
                          <TableCell align="center">{title}</TableCell>
                          <TableCell align="center">{genre.name}</TableCell>
                          <TableCell align="center">{numberInStock}</TableCell>
                          <TableCell align="center">{dailyRentalRate}</TableCell>
                          <TableCell align="center">
                            {favorite ? (
                              <Favorite
                                color="success"
                                onClick={() => handleFavoriteChanged(_id)}
                              />
                            ) : (
                              <FavoriteBorder onClick={() => handleFavoriteChanged(_id)} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => handleDelete(_id)}
                              variant="contained"
                              color="error"
                            >
                              DELETE
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {_.range(maxResultsPerPage - moviesToPresent().length).map((number: number) => (
                      <TableRow key={number} /*style={{ height: "69.39px" }}*/></TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* Show pagination if there is 2 or more pages left  */}
          {Movies.length / maxResultsPerPage > 0 && (
            <Pagination
              className="pegination-container"
              onChange={(e, page: number) => setCurrentPage(page)}
              count={
                (Movies.length / maxResultsPerPage) % 1 > 0
                  ? Math.trunc(Movies.length / maxResultsPerPage) + 1
                  : Movies.length / maxResultsPerPage
              }
              color="primary"
              page={currentPage}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Movies;
