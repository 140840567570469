import React, { FunctionComponent } from "react";
import { Chip, Button, Grid } from "@mui/material";
import "./counter.css";

interface CounterProps {
  value: number;
  onChange: Function;
  onDelete: Function;
}

const Counter: FunctionComponent<CounterProps> = ({ value, onChange, onDelete }) => {
  return (
    <Grid container spacing={2} className="counter-container">
      <Grid item xs={1}>
        <Button
          onClick={() => onChange(value - 1 < 0 ? 0 : value - 1)}
          disabled={value <= 0}
          variant="contained"
          color="warning"
        >
          -
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Chip variant="filled" color="success" label={value === 0 ? "Zero" : value} />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={() => onChange(value + 1)} variant="contained" color="primary">
          +
        </Button>
      </Grid>
      <Grid item xs={1.5}>
        <Button onClick={() => onDelete()} variant="contained" color="error">
          DELETE
        </Button>
      </Grid>
    </Grid>
  );
};

export default Counter;
